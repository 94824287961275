import { useEffect, useRef, useState } from "react";
import LandingAnimate from "../../components/LandingAnimate";
import ImgCollage from "../../components/ImgCollage";
import MailchimpForm from "../../components/RegisterForm";
import Footer from "../../components/Footer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import { ReactLenis } from "@studio-freight/react-lenis";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const mainNode = useRef();
  const mapWrapper = useRef();
  const mapCoverNode = useRef();

  const mapMobile = window.matchMedia("(max-width: 667px)");

  const [show, setShow] = useState("flex");

  useEffect(() => {
    // mobileVersion for Map mobile
    if (mapMobile.matches) {
      mapWrapper.current.scrollLeft = (1550 - window.innerWidth) / 2;
      // show map , make cover disappear
      mapCoverNode.current.addEventListener("touchstart", (e) => {
        e.preventDefault();
        setShow("none");
      });
    }

    let ctx = gsap.context(() => {
      //   gsap.to(".main-body", {
      //     opacity: 1,
      //     ease: "power2.easeOut",
      //     delay: 0.5,
      //     duration: 0.5,
      //   });

      ScrollTrigger.create({
        trigger: ".bnr",
        start: "top top",
        pin: true,
        pinSpacing: false,
      });

      // new SplitType(".appear-text");
      // gsap.to(".appear-text .char", {
      //   opacity: 1,
      //   y: 0,
      //   stagger: 0.01,
      //   delay: 0.5,
      //   duration: 0.1,
      //   ease: "expo.out",
      // });
      // delay: 0.8,
      // duration: 0.2,

      // unveiling homes ---- show text in one-time
      // let headingTexts = gsap.utils.toArray(".heading-text");
      // gsap.to(headingTexts, {
      //   duration: 0.8,
      //   y: "0%",
      //   ease: "power2.easeOut",
      //   stagger: 0.2,
      //   scrollTrigger: {
      //     trigger: headingTexts,
      //     start: "top 100%",
      //     end: "top 20%",
      //     scrub: 2,
      //     markers: false,
      //   },
      // });

      // reveal type
      // const splitTypes = gsap.utils.toArray(".reveal-type");
      // splitTypes.forEach((char, i) => {
      //   const text = new SplitType(char, { types: "chars" });

      //   gsap.from(text.chars, {
      //     scrollTrigger: {
      //       trigger: char,
      //       start: "top 100%",
      //       end: "top 40%",
      //       scrub: 2,
      //       markers: false,
      //     },
      //     opacity: 0.2,
      //     stagger: 0.1,
      //   });
      // });

      // const ParaOnly = gsap.timeline({
      //   defaults: { ease: "none" },
      //   scrollTrigger: {
      //     trigger: ".para-text",
      //     start: "top 100%",
      //     end: "top 100%",
      //     scrub: 10,
      //     // pin: '.wrapperr',
      //   },
      // });

      // ParaOnly.from(".para-text", {
      //   opacity: 0,
      //   y: 100,
      //   ease: "power3.out",
      //   duration: 3,
      //   transformOrigin: "center top",
      // });

      // const WelcomeParaOnly = gsap.timeline({
      //   defaults: { ease: "none" },
      //   scrollTrigger: {
      //     trigger: ".welcome-text",
      //     start: "top 100%",
      //     end: "top 100%",
      //     scrub: 15,
      //     // pin: '.wrapperr',
      //   },
      // });

      // WelcomeParaOnly.from(".welcome-text", {
      //   opacity: 0,
      //   y: 100,
      //   ease: "power3.out",
      //   duration: 3,
      //   transformOrigin: "center top",
      // });

      //novara logo
      // gsap.set(".novara-wrapper", {
      //   opacity: 0,
      //   y: 100,
      // });

      // gsap.to(".novara-wrapper", {
      //   opacity: 1,
      //   y: 0,
      //   ease: "power3.out",
      //   duration: 2,
      //   stagger: 0.04,
      //   scrollTrigger: {
      //     trigger: ".novara-logo",
      //     start: "top 100%",
      //     end: "bottom 20%",
      //     scrub: true,
      //     markers: false,
      //   },
      // });
    });

    return () => ctx.revert();
  }, [mapMobile.matches]);

  return (
    <ReactLenis root options={{ smoothTouch: true }}>
      {/* <LandingAnimate /> */}
      <div ref={mainNode} className="main-body">
        <div>
          <section className="bnr global-padding is-relative">
            <div className="heading-wrapper">
              <h1>
                <div className="appear-text">Modern</div>
                <div className="appear-text is-lightgreen">
                  Parkside <span>Residences</span>
                </div>
              </h1>
              <div
                className="subject appear-text"
                style={{ textTransform: "uppercase" }}
              >
                21 GARDEN TOWNHOMES at cambie park
              </div>
            </div>
          </section>
        </div>
        <div style={{ position: "relative", zIndex: 2, overflow: "hidden" }}>
          {mapMobile.matches ? (
            <img
              className="townhouse-view"
              src="images/townhouse-view-m.jpg"
              alt="townhouse view"
            />
          ) : (
            <img
              className="townhouse-view"
              src="images/townhouse-view.jpg"
              alt="townhouse view"
            />
          )}
          <section className="unveil-homes global-padding">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-10" style={{ padding: "0px" }}>
                  <h2>
                    <div>
                      <span className="heading-text">Parkside Homes</span>
                    </div>
                    <div>
                      <span className="heading-text">for growing</span>
                    </div>
                    <div>
                      <span className="heading-text">Westside Families</span>
                    </div>
                  </h2>
                </div>
                <div className="col-12 col-md-2"></div>
              </div>
              <div className="row">
                <div className=" col-md-6"></div>
                <div
                  className="col-12 col-md-6 gardenTownhomes-intro"
                  style={{ padding: "0px" }}
                >
                  {/* <div className="unveilImg" style={{overflow:'hidden'}}>
                    <img
                      src="images/21-garden-townhomes.jpg"
                      alt="21-garden-townhomes"
                    />
                  </div> */}
                  <p className="para-text">
                    Introducing Ashlyn, a timeless collection of 21 parkside
                    townhomes. Poised at the untouched, quiet corner of Ash and
                    54th, Ashlyn’s 3 bedroom homes stand alongside beautiful
                    trees in the otherwise bustling and highly-coveted Oakridge
                    locale.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {mapMobile.matches ? (
            <img
              className="img-al global-padding"
              src="images/AL-1-m.jpg"
              alt="townhouse view"
            />
          ) : (
            <img
              className="img-al global-padding"
              src="images/AL-1.jpg"
              alt="townhouse view"
            />
          )}
          <section className="image-collage-section global-padding">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-11">
                  <h2>
                    <span className="reveal-type">
                      <span className="desktop-immerse">
                        A CONVENIENT,
                        <br />
                        CONNECTED COMMUNITY
                      </span>
                      <span className="mobile-immerse">
                        A CONVENIENT,
                        <br />
                        CONNECTED COMMUNITY
                      </span>
                    </span>
                  </h2>
                </div>
                <div className="col-lg-1"></div>
              </div>
            </div>
            <div className="welcome-trigger">
              <ImgCollage />
            </div>
            <p className="welcome-text">
              Welcome to the neighbourhood, where city rhythms harmonize with
              family ties. Ashlyn is the idyllic setting for the growing family
              to experience outdoor adventures while remaining close to a myriad
              of urban conveniences.
            </p>
          </section>
          <section className="map is-relative">
            <div className="details-wrapper">
              <img
                loading="lazy"
                className="map-icon desktop-map-details"
                src="images/icon/map-icon.png"
                alt="Map icon"
              />
              <ul className="details desktop-map-details">
                <li className="detail-wrapper is-flex">
                  <img
                    loading="lazy"
                    className="arrow-icon"
                    src="images/icon/arrow.png"
                    alt="arrow icon"
                  />
                  <h3 className="is-flex">
                    <span>15</span>
                    <span className="min">
                      Min to
                      <span className="location">Downtown</span>
                    </span>
                  </h3>
                </li>
                <li className="detail-wrapper is-flex">
                  <img
                    loading="lazy"
                    className="arrow-icon"
                    src="images/icon/arrow.png"
                    alt="arrow icon"
                  />
                  <h3 className="is-flex">
                    <span>18</span>
                    <span className="min">
                      Min to
                      <span className="location">UBC</span>
                    </span>
                  </h3>
                </li>
                <li className="detail-wrapper is-flex">
                  <img
                    loading="lazy"
                    className="arrow-icon"
                    src="images/icon/arrow.png"
                    alt="arrow icon"
                  />
                  <h3 className="is-flex">
                    <span>10</span>
                    <span className="min">
                      Min to
                      <span className="location">YVR Airport</span>
                    </span>
                  </h3>
                </li>
                <li className="detail-wrapper is-flex">
                  <img
                    loading="lazy"
                    className="arrow-icon"
                    src="images/icon/arrow.png"
                    alt="arrow icon"
                  />
                  <h3 className="is-flex">
                    <span>15</span>
                    <span className="min">
                      Min to
                      <span className="location">Richmond</span>
                    </span>
                  </h3>
                </li>
                <li className="detail-wrapper is-flex">
                  <img
                    loading="lazy"
                    className="arrow-icon"
                    src="images/icon/arrow.png"
                    alt="arrow icon"
                  />
                  <h3 className="is-flex">
                    <span>18</span>
                    <span className="min">
                      Min to
                      <span className="location">Burnaby</span>
                    </span>
                  </h3>
                </li>
              </ul>
            </div>
            <div ref={mapWrapper} className="mapImg-wrapper is-relative">
              <div
                ref={mapCoverNode}
                className="map-cover-wrapper"
                style={{ display: mapMobile.matches ? show : "none" }}
              >
                <div className="map-cover"></div>
                <img
                  loading="lazy"
                  className="is-relative"
                  src="images/icon/swipe.png"
                  alt="swipe"
                />
              </div>
              <img
                //loading="lazy"
                src="images/Ashlyn_Map.png"
                alt="Ashlyn Map"
              />
            </div>
            <ul className="details mobile-map-details">
              <li className="detail-wrapper is-flex">
                <img
                  loading="lazy"
                  className="arrow-icon"
                  src="images/icon/green-arrow.png"
                  alt="arrow icon"
                />
                <h3 className="is-flex">
                  <span>15</span>
                  <span className="min">
                    Min to
                    <span className="location">Downtown</span>
                  </span>
                </h3>
              </li>
              <li className="detail-wrapper is-flex">
                <img
                  loading="lazy"
                  className="arrow-icon"
                  src="images/icon/green-arrow.png"
                  alt="arrow icon"
                />
                <h3 className="is-flex">
                  <span>18</span>
                  <span className="min">
                    Min to
                    <span className="location">Burnaby</span>
                  </span>
                </h3>
              </li>
              <li className="detail-wrapper is-flex">
                <img
                  loading="lazy"
                  className="arrow-icon"
                  src="images/icon/green-arrow.png"
                  alt="arrow icon"
                />
                <h3 className="is-flex">
                  <span>18</span>
                  <span className="min">
                    Min to
                    <span className="location">UBC</span>
                  </span>
                </h3>
              </li>
              <li className="detail-wrapper is-flex">
                <img
                  loading="lazy"
                  className="arrow-icon"
                  src="images/icon/green-arrow.png"
                  alt="arrow icon"
                />
                <h3 className="is-flex">
                  <span>15</span>
                  <span className="min">
                    Min to
                    <span className="location">Richmond</span>
                  </span>
                </h3>
              </li>
              <li className="detail-wrapper is-flex">
                <img
                  loading="lazy"
                  className="arrow-icon"
                  src="images/icon/green-arrow.png"
                  alt="arrow icon"
                />
                <h3 className="is-flex">
                  <span>10</span>
                  <span className="min">
                    Min to
                    <span className="location">YVR Airport</span>
                  </span>
                </h3>
              </li>
            </ul>
          </section>
          <section className="novara">
            <div className="novara-wrapper container">
              <div className="row">
                <div className="col-12 col-lg-5" style={{ padding: "0px" }}>
                  <div>
                    <img
                      loading="lazy"
                      className="novara-logo"
                      src="images/logo/novara-logo.png"
                      alt="NOVARA logo"
                    />
                    <img
                      loading="lazy"
                      className="ashlyn-sideview-m"
                      src="images/Ashlyn_SideView.jpg"
                      alt="Ashlyn sideview"
                    />
                    <p className="novara-text">
                      Founded in 2011, Novara Properties is a family-owned
                      enterprise that identifies opportunities for and invests
                      in the development of residential real estate in and
                      around the Vancouver area. From the beginning, our aim has
                      been to provide exceptional quality and value for clients
                      by developing functional, innovative and characteristic
                      residential real estate, which blend into and enhance our
                      local community.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-5" style={{ padding: "0px" }}>
                  <img
                    loading="lazy"
                    className="ashlyn-sideview"
                    src="images/Ashlyn_SideView.jpg"
                    alt="Ashlyn sideview"
                  />
                </div>
              </div>
            </div>
          </section>
          <MailchimpForm />
          <Footer />
        </div>
      </div>
    </ReactLenis>
  );
};

export default Home;
